<template>
  <v-container>
    <v-row>
      <v-col cols="12"
             md="11">
        <h1>Customers</h1>
      </v-col>
      <v-col cols="12"
             md="1">
        <v-menu
          bottom
          left>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
        </v-menu>
      </v-col>
    </v-row>
    <customers-list />
    <v-btn
      class="mt-5"
      color="primary"
      outlined
      :loading="reportExportLoading"
      :disabled="reportExportLoading"
      @click="exportDataCustomer">
      <template v-slot:loader>
        <span class="custom-loader">
          <v-icon light>mdi-cached</v-icon>
        </span>
      </template>
      <v-icon class="mr-2">mdi-file-export-outline</v-icon>
      Export
    </v-btn>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CustomerRegistrations',
  components: {
    CustomersList: () => import('@/components/Customer/customerList.vue')
  },
  data () {
    return {
      openDownloadFile: false,
      downloadProgress: 0
    }
  },
  computed: {
    ...mapGetters({
      reportExportLoading: 'customers/GET_REPORT_EXPORT_LOADING'
    })
  },

  methods: {
    async exportDataCustomer() {
      
      // this.openDownloadFile = true;
      // const config = {
      //   onUploadProgress(progressEvent) {
      //     const percentCompleted = Math.round((progressEvent.loaded * 100) /
      //       progressEvent.total);

      //     // execute the callback
      //     if (onProgress) onProgress(percentCompleted)

      //     return percentCompleted;
      //   },
      // };
      await this.$store.dispatch('customers/ExportCustomerData');

    }
  }
};
</script>
